import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button, Form } from "react-bootstrap"
import { CurveCarveTop, CurveBulgeBottom } from "../components/Curves/Curve"
import { PropertyGrid } from "../components/Property/PropertyGrid"
import { graphql } from "gatsby"
import { FaCaretUp, FaCaretDown } from "react-icons/fa"
import { Newsletter } from "../components/Misc/Newsletter"

const SearchResult = ({ data }) => {
  const {
    allContentfulProperty: { nodes: properties },
  } = data

  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    isAccending: false,
  })

  let sortedProperties = [...properties]

  sortedProperties.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.isAccending ? -1 : 1
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.isAccending ? 1 : -1
    }
    return 0
  })

  const requestSort = key => {
    setSortConfig({ key, isAccending: sortConfig.isAccending })
  }

  const handleSortDirectionChange = e => {
    e.preventDefault()
    setSortConfig({ ...sortConfig, isAccending: !sortConfig.isAccending })
  }

  return (
    <Layout>
      <SEO
        title="Search Results"
        description="Take the next step in your investment journey and search for a property that meets your requirements. From residential developments in Manchester to care homes in Liverpool, we offer a wide range of property investments across the United Kingdom."
      />
      <Container>
        <Row>
          <Col>
            <h1>Search Results</h1>

            <div className="text-center">
              <p>
                Your investment property search has begun. Choose to sort your
                properties by; Price, Yield, or Completion Date. Use the arrow
                to rank from highest to lowest.
              </p>

              <p>
                To search investment by sector, click on investment and choose
                the sector that you are most comfortable with.
              </p>

              <p>
                Our team are at hand to guide you through your search. They will
                empower you to take a decision with confidence and ease. Arrange
                a call!
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <Form inline className="justify-content-center my-4">
              <Form.Label className="mr-2" htmlFor="sortCriteria">
                Sort by:
              </Form.Label>
              <Form.Control
                as="select"
                className="m-2"
                id="sortCriteria"
                custom
                onChange={e => requestSort(e.target.value)}
              >
                <option value="createdAt">Latest</option>
                <option value="startingPrice">Price</option>
                <option value="yield">Yield</option>
                <option value="completionDate">Completion Date</option>
                <option value="name">Title</option>
              </Form.Control>

              <Button
                type="submit"
                variant="secondary"
                onClick={e => handleSortDirectionChange(e)}
              >
                {sortConfig.isAccending ? <FaCaretUp /> : <FaCaretDown />}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>

      <CurveCarveTop variant="light" />
      <div className="bg-light pt-5">
        <PropertyGrid properties={sortedProperties} />
      </div>
      <CurveBulgeBottom variant="light" />

      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query SearchResultQuery {
    allContentfulProperty(sort: { fields: startingPrice, order: ASC }) {
      nodes {
        ...PropertyListFragment
        # Extras for search / ordering
        createdAt
        completionDate
      }
    }
  }
`

export default SearchResult
