import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { PropertyCard } from "./PropertyCard"

export const PropertyGrid = ({ properties, displayCount }) => {
  if (!properties) return null

  //  Fillter out properties which have been marked as sold.
  //  Only return properties where sold === false
  let propertiesForSale = properties.filter(property => !property.sold)

  //  If there's a display count, limit it to this figure
  //  Prepare number for "slice" below
  const numberToShow = displayCount || propertiesForSale.length

  return (
    <Container>
      <Row>
        {propertiesForSale.slice(0, numberToShow).map(property => {
          return (
            <Col
              key={property.id}
              xs={12}
              md={6}
              xl={4}
              className="mx-auto d-flex"
            >
              <PropertyCard property={property} />
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}
